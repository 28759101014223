import React from "react";
//import heroThu from '../../assets/images/me/sat-3.gif';
import heroThumb from "../../assets/images/me/sat.gif";
// import heroThumb from "../../assets/images/me/sat.png";
import shapeTen from "../../assets/images/shape/shape-10.png";
import shapeEleven from "../../assets/images/shape/shape-11.png";
import shapeTwelve from "../../assets/images/shape/shape-12.png";
import shapeNine from "../../assets/images/shape/shape-9.png";

function HeroHomeTwo() {
  return (
    <>
      <section
        className="appie-hero-area-2 mb-90"
        style={{ marginTop: "-140px", background: "white" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="appie-hero-content-2">
                {/* <span>14 day free</span> */}
                <h1 className="appie-title">
                  {/* Ready to launch your app in one, <span>Application</span> */}
                  Like Never Before, Proven Way To Get You Higher SAT Score,
                  Fast!
                </h1>
                <ul style={{ listStyleType: "square" }}>
                  <li>200 Point Score Increase Guarantee</li>
                  <li>Highest Quality Instructional Materials</li>
                  <li>Just 10 Minutes Per Day!</li>
                  <li>No Risk - Money Back Guarantee</li>
                </ul>
                <p>
                  Across Africa, SAT candidates lack access to smart and
                  affordable prep. The available and expensive option is also
                  not generating the score increase and fast enough. Like never
                  before, you can now smack your SAT, Ace your score and hug
                  your success.
                </p>
                <p></p>
                <form action="#">
                  <div className="input-box">
                    <input
                      type="text"
                      placeholder="Enter your email address for deals"
                    />
                    <i className="fal fa-envelope" />
                    <button type="button">
                      <i className="fal fa-arrow-right" />
                    </button>
                  </div>
                </form>
                {/* <div className="hero-users">
                                    <img src={heroThu} alt="dgfrea" />
                                    
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div
          className="appie-hero-thumb-3 wow animated fadeInRight"
          data-wow-duration="2000ms"
          data-wow-delay="400ms"
        >
          <img
            src={heroThumb}
            alt=""
            style={{ marginLeft: "30px", marginBottom: "200px" }}
          />
          {/* <img src={heroThumb} alt=""/> */}
        </div>
        <div className="hero-shape-1">
          <img src={shapeNine} alt="" />
        </div>
        <div className="hero-shape-2">
          <img src={shapeTen} alt="" />
        </div>
        <div className="hero-shape-3">
          <img src={shapeEleven} alt="" />
        </div>
        <div className="hero-shape-4">
          <img src={shapeTwelve} alt="" />
        </div>
      </section>
    </>
  );
}

export default HeroHomeTwo;
